//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[NeptunePageType]

export enum NeptunePageTypeEnum {
  HomePage = 1,
  About = 2,
  OrganizationsList = 3,
  HomeMapInfo = 4,
  HomeAdditionalInfo = 5,
  TreatmentBMP = 6,
  TreatmentBMPType = 7,
  Jurisdiction = 9,
  Assessment = 10,
  ManageObservationTypesList = 11,
  ManageTreatmentBMPTypesList = 12,
  ManageObservationTypeInstructions = 13,
  ManageObservationTypeObservationInstructions = 14,
  ManageObservationTypeLabelsAndUnitsInstructions = 15,
  ManageTreatmentBMPTypeInstructions = 16,
  ManageCustomAttributeTypeInstructions = 17,
  ManageCustomAttributeInstructions = 18,
  ManageCustomAttributeTypesList = 19,
  Legal = 20,
  FundingSourcesList = 21,
  FindABMP = 22,
  LaunchPad = 23,
  FieldRecords = 24,
  RequestSupport = 25,
  InviteUser = 26,
  WaterQualityMaintenancePlan = 27,
  ParcelList = 28,
  Training = 29,
  ManagerDashboard = 30,
  WaterQualityMaintenancePlanOandMVerifications = 31,
  ModelingHomePage = 32,
  TrashHomePage = 33,
  OVTAInstructions = 34,
  OVTAIndex = 35,
  TrashModuleProgramOverview = 36,
  DelineationMap = 37,
  BulkUploadRequest = 38,
  TreatmentBMPAssessment = 41,
  EditOVTAArea = 42,
  LandUseBlock = 43,
  ExportAssessmentGeospatialData = 44,
  HRUCharacteristics = 45,
  RegionalSubbasins = 46,
  DelineationReconciliationReport = 47,
  ViewTreatmentBMPModelingAttributes = 48,
  UploadTreatmentBMPs = 49,
  AboutModelingBMPPerformance = 50,
  BulkUploadFieldVisits = 51,
  HippocampHomePage = 52,
  HippocampTraining = 53,
  HippocampLabelsAndDefinitionsList = 54,
  HippocampAbout = 55,
  HippocampProjectsList = 56,
  HippocampProjectInstructions = 57,
  HippocampProjectBasics = 58,
  HippocampProjectAttachments = 59,
  HippocampTreatmentBMPs = 60,
  HippocampDelineations = 61,
  HippocampModeledPerformance = 62,
  HippocampReview = 63,
  HippocampPlanningMap = 64,
  OCTAM2Tier2GrantProgramMetrics = 65,
  OCTAM2Tier2GrantProgramDashboard = 66,
  EditWQMPBoundary = 67,
  UploadWQMPs = 68
}
