<div class="container-fluid text-light my-0 py-0 d-none d-sm-block">
    <div class="row mt-0">
        <div class="col-sm homepageJumbotronImage" id="homepageJumbotronImage1"></div>
    </div>
</div>

<div class="container mt-4">
    <div *ngIf="userIsUnassigned()" class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            <span *ngIf="userIsOCTAGrantReviewer(); else unassignedUserMessage">
                Welcome {{currentUser.FullName}}. You have successfully logged in to the OCST Planning Module, 
                and an administrator has granted you read-only access to Review Grant Programs. 
                You may <a href="{{requestSupportUrl()}}">Request Support</a> to contact the Administrators with any questions or comments.
            </span>
            <ng-template #unassignedUserMessage>
                <span>
                    Welcome {{currentUser.FullName}}. You have successfully logged in to the OCST Planning Module,
                    but your account is not yet configured. You will receive an email from the Administrators when your account is ready to
                    use. You may <a href="{{requestSupportUrl()}}">Request Support</a> to contact the Administrators with any questions or
                    comments.
                </span>
            </ng-template>
        </div>
    </div>

    <app-alert-display></app-alert-display>
    <h1 class="text-primary mb-4">OCST Planning Module</h1>
    <div class="row mt-sm-4">
        <div class="col-md-4 order-md-12 order-1 col-sm-12">
            <div class="card">
                <div class="card-header">
                    <ng-container *ngIf="!currentUser">Access</ng-container>
                    <ng-container *ngIf="currentUser">Quick actions</ng-container>
                </div>
                <div class="card-body" *ngIf="!currentUser">
                    <div class="row">
                        <div class="col">
                            Log in to view your Account. Create an Account if you don't have one yet.
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col mx-1">
                            <button (click)="login()" class="btn btn-hippocamp mr-1">Login</button>
                            <button (click)="createAccount()" class="btn btn-hippocamp ml-1">Create
                                Account</button>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <small style="font-size:11px;">
                                Need help logging in? <br />
                                <a href="{{forgotPasswordUrl()}}">Forgot Password</a> | <a
                                    href="{{forgotUsernameUrl()}}">Forgot
                                    Username</a> | <a href="{{keystoneSupportUrl()}}">Request Support</a>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="currentUser">
                    <a class="btn btn-hippocamp mb-1 w-100" routerLink="/projects">View Projects</a>
                    <a class="btn btn-hippocamp mb-1 w-100" routerLink="/projects/new">Add a Project</a>
                    <a class="btn btn-hippocamp mb-1 w-100" routerLink="/planning-map">Planning Map</a>
                    <a class="btn btn-hippocamp mb-1 w-100" href="{{ocstBaseUrl()}}">OCST Home</a>
                    <a class="btn btn-hippocamp mb-1 w-100" href="{{requestSupportUrl()}}">Request Support</a>
                </div>
            </div>
        </div>
        
        <div class="col-md-8 order-md-1 order-12 col-sm-12">
            <div class="mt-2">
                <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>
</div>