//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[UnderlyingHydrologicSoilGroup]

export enum UnderlyingHydrologicSoilGroupEnum {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  Liner = 5
}
