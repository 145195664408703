/**
 * Neptune.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DelineationDto } from '../model/delineation-dto';
import { DelineationUpsertDto } from '../model/delineation-upsert-dto';
import { ProblemDetails } from '../model/problem-details';
import { ProjectDocumentDto } from '../model/project-document-dto';
import { ProjectDocumentUpdateDto } from '../model/project-document-update-dto';
import { ProjectDto } from '../model/project-dto';
import { ProjectLoadGeneratingResultDto } from '../model/project-load-generating-result-dto';
import { ProjectLoadReducingResultDto } from '../model/project-load-reducing-result-dto';
import { ProjectNetworkSolveHistorySimpleDto } from '../model/project-network-solve-history-simple-dto';
import { ProjectSimpleDto } from '../model/project-simple-dto';
import { ProjectUpsertDto } from '../model/project-upsert-dto';
import { TreatmentBMPHRUCharacteristicsSummarySimpleDto } from '../model/treatment-bmphru-characteristics-summary-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param attachmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsAttachmentsAttachmentIDDelete(attachmentID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsAttachmentsAttachmentIDDelete(attachmentID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsAttachmentsAttachmentIDDelete(attachmentID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsAttachmentsAttachmentIDDelete(attachmentID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachmentID === null || attachmentID === undefined) {
            throw new Error('Required parameter attachmentID was null or undefined when calling projectsAttachmentsAttachmentIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/projects/attachments/${encodeURIComponent(String(attachmentID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param attachmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsAttachmentsAttachmentIDGet(attachmentID: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectDocumentDto>;
    public projectsAttachmentsAttachmentIDGet(attachmentID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectDocumentDto>>;
    public projectsAttachmentsAttachmentIDGet(attachmentID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectDocumentDto>>;
    public projectsAttachmentsAttachmentIDGet(attachmentID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachmentID === null || attachmentID === undefined) {
            throw new Error('Required parameter attachmentID was null or undefined when calling projectsAttachmentsAttachmentIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectDocumentDto>(`${this.basePath}/projects/attachments/${encodeURIComponent(String(attachmentID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param attachmentID 
     * @param projectDocumentUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsAttachmentsAttachmentIDPut(attachmentID: number, projectDocumentUpdateDto?: ProjectDocumentUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<ProjectDocumentDto>;
    public projectsAttachmentsAttachmentIDPut(attachmentID: number, projectDocumentUpdateDto?: ProjectDocumentUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectDocumentDto>>;
    public projectsAttachmentsAttachmentIDPut(attachmentID: number, projectDocumentUpdateDto?: ProjectDocumentUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectDocumentDto>>;
    public projectsAttachmentsAttachmentIDPut(attachmentID: number, projectDocumentUpdateDto?: ProjectDocumentUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachmentID === null || attachmentID === undefined) {
            throw new Error('Required parameter attachmentID was null or undefined when calling projectsAttachmentsAttachmentIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProjectDocumentDto>(`${this.basePath}/projects/attachments/${encodeURIComponent(String(attachmentID))}`,
            projectDocumentUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsDelineationsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DelineationDto>>;
    public projectsDelineationsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DelineationDto>>>;
    public projectsDelineationsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DelineationDto>>>;
    public projectsDelineationsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DelineationDto>>(`${this.basePath}/projects/delineations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsDownloadGet(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public projectsDownloadGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public projectsDownloadGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public projectsDownloadGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/projects/download`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectDto>>;
    public projectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectDto>>>;
    public projectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectDto>>>;
    public projectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectDto>>(`${this.basePath}/projects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsNewPost(projectUpsertDto?: ProjectUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ProjectDto>;
    public projectsNewPost(projectUpsertDto?: ProjectUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectDto>>;
    public projectsNewPost(projectUpsertDto?: ProjectUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectDto>>;
    public projectsNewPost(projectUpsertDto?: ProjectUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProjectDto>(`${this.basePath}/projects/new`,
            projectUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsOCTAM2Tier2GrantProgramDownloadGet(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public projectsOCTAM2Tier2GrantProgramDownloadGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public projectsOCTAM2Tier2GrantProgramDownloadGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public projectsOCTAM2Tier2GrantProgramDownloadGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/projects/OCTAM2Tier2GrantProgram/download`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsOCTAM2Tier2GrantProgramGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectDto>>;
    public projectsOCTAM2Tier2GrantProgramGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectDto>>>;
    public projectsOCTAM2Tier2GrantProgramGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectDto>>>;
    public projectsOCTAM2Tier2GrantProgramGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectDto>>(`${this.basePath}/projects/OCTAM2Tier2GrantProgram`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsOCTAM2Tier2GrantProgramTreatmentBMPsDownloadGet(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public projectsOCTAM2Tier2GrantProgramTreatmentBMPsDownloadGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public projectsOCTAM2Tier2GrantProgramTreatmentBMPsDownloadGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public projectsOCTAM2Tier2GrantProgramTreatmentBMPsDownloadGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/projects/OCTAM2Tier2GrantProgram/treatmentBMPs/download`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDAttachmentsGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectDocumentDto>>;
    public projectsProjectIDAttachmentsGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectDocumentDto>>>;
    public projectsProjectIDAttachmentsGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectDocumentDto>>>;
    public projectsProjectIDAttachmentsGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDAttachmentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectDocumentDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/attachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param projectID2 
     * @param fileResource 
     * @param displayName 
     * @param documentDescription 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDAttachmentsPost(projectID: number, projectID2: number, fileResource: Blob, displayName: string, documentDescription?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsProjectIDAttachmentsPost(projectID: number, projectID2: number, fileResource: Blob, displayName: string, documentDescription?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsProjectIDAttachmentsPost(projectID: number, projectID2: number, fileResource: Blob, displayName: string, documentDescription?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsProjectIDAttachmentsPost(projectID: number, projectID2: number, fileResource: Blob, displayName: string, documentDescription?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDAttachmentsPost.');
        }

        if (projectID2 === null || projectID2 === undefined) {
            throw new Error('Required parameter projectID2 was null or undefined when calling projectsProjectIDAttachmentsPost.');
        }

        if (fileResource === null || fileResource === undefined) {
            throw new Error('Required parameter fileResource was null or undefined when calling projectsProjectIDAttachmentsPost.');
        }

        if (displayName === null || displayName === undefined) {
            throw new Error('Required parameter displayName was null or undefined when calling projectsProjectIDAttachmentsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (projectID2 !== undefined) {
            formParams = formParams.append('ProjectID', <any>projectID2) || formParams;
        }
        if (fileResource !== undefined) {
            formParams = formParams.append('FileResource', <any>fileResource) || formParams;
        }
        if (displayName !== undefined) {
            formParams = formParams.append('DisplayName', <any>displayName) || formParams;
        }
        if (documentDescription !== undefined) {
            formParams = formParams.append('DocumentDescription', <any>documentDescription) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/attachments`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDCopyPost(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public projectsProjectIDCopyPost(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public projectsProjectIDCopyPost(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public projectsProjectIDCopyPost(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDCopyPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<number>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/copy`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDDeleteDelete(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsProjectIDDeleteDelete(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsProjectIDDeleteDelete(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsProjectIDDeleteDelete(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDDeleteDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDDelineationsGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DelineationUpsertDto>>;
    public projectsProjectIDDelineationsGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DelineationUpsertDto>>>;
    public projectsProjectIDDelineationsGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DelineationUpsertDto>>>;
    public projectsProjectIDDelineationsGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDDelineationsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DelineationUpsertDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/delineations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param delineationUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDDelineationsPut(projectID: number, delineationUpsertDto?: Array<DelineationUpsertDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsProjectIDDelineationsPut(projectID: number, delineationUpsertDto?: Array<DelineationUpsertDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsProjectIDDelineationsPut(projectID: number, delineationUpsertDto?: Array<DelineationUpsertDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsProjectIDDelineationsPut(projectID: number, delineationUpsertDto?: Array<DelineationUpsertDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDDelineationsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/delineations`,
            delineationUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectSimpleDto>;
    public projectsProjectIDGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectSimpleDto>>;
    public projectsProjectIDGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectSimpleDto>>;
    public projectsProjectIDGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectSimpleDto>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDLoadGeneratingResultsGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectLoadGeneratingResultDto>>;
    public projectsProjectIDLoadGeneratingResultsGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectLoadGeneratingResultDto>>>;
    public projectsProjectIDLoadGeneratingResultsGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectLoadGeneratingResultDto>>>;
    public projectsProjectIDLoadGeneratingResultsGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDLoadGeneratingResultsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectLoadGeneratingResultDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/load-generating-results`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDLoadReducingResultsGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectLoadReducingResultDto>>;
    public projectsProjectIDLoadReducingResultsGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectLoadReducingResultDto>>>;
    public projectsProjectIDLoadReducingResultsGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectLoadReducingResultDto>>>;
    public projectsProjectIDLoadReducingResultsGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDLoadReducingResultsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectLoadReducingResultDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/load-reducing-results`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDModeledPerformancePost(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsProjectIDModeledPerformancePost(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsProjectIDModeledPerformancePost(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsProjectIDModeledPerformancePost(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDModeledPerformancePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/modeled-performance`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDProjectNetworkSolveHistoriesGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectNetworkSolveHistorySimpleDto>>;
    public projectsProjectIDProjectNetworkSolveHistoriesGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectNetworkSolveHistorySimpleDto>>>;
    public projectsProjectIDProjectNetworkSolveHistoriesGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectNetworkSolveHistorySimpleDto>>>;
    public projectsProjectIDProjectNetworkSolveHistoriesGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDProjectNetworkSolveHistoriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectNetworkSolveHistorySimpleDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/project-network-solve-histories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDTreatmentBmpHruCharacteristicsGet(projectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TreatmentBMPHRUCharacteristicsSummarySimpleDto>>;
    public projectsProjectIDTreatmentBmpHruCharacteristicsGet(projectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TreatmentBMPHRUCharacteristicsSummarySimpleDto>>>;
    public projectsProjectIDTreatmentBmpHruCharacteristicsGet(projectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TreatmentBMPHRUCharacteristicsSummarySimpleDto>>>;
    public projectsProjectIDTreatmentBmpHruCharacteristicsGet(projectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDTreatmentBmpHruCharacteristicsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TreatmentBMPHRUCharacteristicsSummarySimpleDto>>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/treatment-bmp-hru-characteristics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param projectID 
     * @param projectUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsProjectIDUpdatePost(projectID: number, projectUpsertDto?: ProjectUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectsProjectIDUpdatePost(projectID: number, projectUpsertDto?: ProjectUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectsProjectIDUpdatePost(projectID: number, projectUpsertDto?: ProjectUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectsProjectIDUpdatePost(projectID: number, projectUpsertDto?: ProjectUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsProjectIDUpdatePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/projects/${encodeURIComponent(String(projectID))}/update`,
            projectUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsTreatmentBMPsDownloadGet(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public projectsTreatmentBMPsDownloadGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public projectsTreatmentBMPsDownloadGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public projectsTreatmentBMPsDownloadGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/projects/treatmentBMPs/download`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
