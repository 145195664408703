/**
 * Neptune.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class ProjectLoadReducingResultDto { 
    TreatmentBMPID?: number;
    TreatmentBMPName?: string;
    ProjectID?: number;
    ProjectName?: string;
    EffectiveAreaAcres?: number;
    DesignStormDepth85thPercentile?: number;
    DesignVolume85thPercentile?: number;
    WetWeatherInflow?: number;
    WetWeatherTreated?: number;
    WetWeatherRetained?: number;
    WetWeatherUntreated?: number;
    WetWeatherTSSReduced?: number;
    WetWeatherTNReduced?: number;
    WetWeatherTPReduced?: number;
    WetWeatherFCReduced?: number;
    WetWeatherTCuReduced?: number;
    WetWeatherTPbReduced?: number;
    WetWeatherTZnReduced?: number;
    WetWeatherTSSInflow?: number;
    WetWeatherTNInflow?: number;
    WetWeatherTPInflow?: number;
    WetWeatherFCInflow?: number;
    WetWeatherTCuInflow?: number;
    WetWeatherTPbInflow?: number;
    WetWeatherTZnInflow?: number;
    SummerDryWeatherInflow?: number;
    SummerDryWeatherTreated?: number;
    SummerDryWeatherRetained?: number;
    SummerDryWeatherUntreated?: number;
    SummerDryWeatherTSSReduced?: number;
    SummerDryWeatherTNReduced?: number;
    SummerDryWeatherTPReduced?: number;
    SummerDryWeatherFCReduced?: number;
    SummerDryWeatherTCuReduced?: number;
    SummerDryWeatherTPbReduced?: number;
    SummerDryWeatherTZnReduced?: number;
    SummerDryWeatherTSSInflow?: number;
    SummerDryWeatherTNInflow?: number;
    SummerDryWeatherTPInflow?: number;
    SummerDryWeatherFCInflow?: number;
    SummerDryWeatherTCuInflow?: number;
    SummerDryWeatherTPbInflow?: number;
    SummerDryWeatherTZnInflow?: number;
    WinterDryWeatherInflow?: number;
    WinterDryWeatherTreated?: number;
    WinterDryWeatherRetained?: number;
    WinterDryWeatherUntreated?: number;
    WinterDryWeatherTSSReduced?: number;
    WinterDryWeatherTNReduced?: number;
    WinterDryWeatherTPReduced?: number;
    WinterDryWeatherFCReduced?: number;
    WinterDryWeatherTCuReduced?: number;
    WinterDryWeatherTPbReduced?: number;
    WinterDryWeatherTZnReduced?: number;
    WinterDryWeatherTSSInflow?: number;
    WinterDryWeatherTNInflow?: number;
    WinterDryWeatherTPInflow?: number;
    WinterDryWeatherFCInflow?: number;
    WinterDryWeatherTCuInflow?: number;
    WinterDryWeatherTPbInflow?: number;
    WinterDryWeatherTZnInflow?: number;
    DryWeatherInflow?: number;
    DryWeatherTreated?: number;
    DryWeatherRetained?: number;
    DryWeatherUntreated?: number;
    DryWeatherTSSReduced?: number;
    DryWeatherTNReduced?: number;
    DryWeatherTPReduced?: number;
    DryWeatherFCReduced?: number;
    DryWeatherTCuReduced?: number;
    DryWeatherTPbReduced?: number;
    DryWeatherTZnReduced?: number;
    DryWeatherTSSInflow?: number;
    DryWeatherTNInflow?: number;
    DryWeatherTPInflow?: number;
    DryWeatherFCInflow?: number;
    DryWeatherTCuInflow?: number;
    DryWeatherTPbInflow?: number;
    DryWeatherTZnInflow?: number;
    TotalInflow?: number;
    TotalTreated?: number;
    TotalRetained?: number;
    TotalUntreated?: number;
    TotalTSSReduced?: number;
    TotalTNReduced?: number;
    TotalTPReduced?: number;
    TotalFCReduced?: number;
    TotalTCuReduced?: number;
    TotalTPbReduced?: number;
    TotalTZnReduced?: number;
    TotalTSSInflow?: number;
    TotalTNInflow?: number;
    TotalTPInflow?: number;
    TotalFCInflow?: number;
    TotalTCuInflow?: number;
    TotalTPbInflow?: number;
    TotalTZnInflow?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
