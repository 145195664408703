<ng-container [class.d-none]="!projectTreatmentBMPs">
<div class="mt-md-0 mt-4">
    <h2 class="d-inline-block">Modeled Performance and Grant Metrics</h2>
    <a href="{{getAboutModelingPerformanceURL()}}" class="float-right">About Modeling Performance</a>
</div>
<div class="row m-1">
    <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
</div>
<div class="row m-1 mb-3">
    <div *ngIf="isMostRecentHistoryOfType(ProjectNetworkHistoryStatusTypeEnum.Queued)" class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            Results for this project are currently being calculated. Triggering a new calculation will be disabled until the
            current calculation completes.
        </div>
    </div>
    <div class="col-4 col-md-2">
        <button class="btn btn-hippocamp" (click)="triggerModelRunForProject()"
            [disabled]="isMostRecentHistoryOfType(ProjectNetworkHistoryStatusTypeEnum.Queued) || delineations?.length == 0">
            Calculate
        </button>
    </div>
    <div class="col-8 col-md-10">
        <p class="font-weight-bold">Press 'Calculate' to run the model results for your project. You will be notified
            via email
            when the updated results are returned. Toggle the dropdown below to view results for individual BMPs.</p>
        <p class="font-italic">{{getModelResultsLastCalculatedText()}}</p>
    </div>
</div>
<div class="row mb-3">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Location
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <table class="table d-block" style="overflow:auto" [style.max-height]="mapHeight">
                            <thead>
                                <th>
                                    BMP Name
                                </th>
                                <th>
                                    <field-definition fieldDefinitionType="TreatmentBMPType" [labelOverride]="'Type'">
                                    </field-definition>
                                </th>
                                <th>
                                    Delineation Area (ac)
                                </th>
                                <th>
                                    <field-definition fieldDefinitionType="FullyParameterized"></field-definition>
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let treatmentBMP of projectTreatmentBMPs" class="selectable"
                                    [class.selected]="selectedTreatmentBMP?.TreatmentBMPID == treatmentBMP.TreatmentBMPID"
                                    (click)="selectFeatureImpl(treatmentBMP.TreatmentBMPID)">
                                    <td>
                                        {{treatmentBMP.TreatmentBMPName}}
                                    </td>
                                    <td>
                                        {{treatmentBMP.TreatmentBMPTypeName}}
                                    </td>
                                    <td>
                                        {{getDelineationAcreageForTreatmentBMP(treatmentBMP.TreatmentBMPID)}}
                                    </td>
                                    <td>
                                        {{treatmentBMP.IsFullyParameterized ? "Yes" : "No"}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-6">
                        <div #mapDiv id={{mapID}} class="location-card" [style.height]="mapHeight"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 mb-3" *ngIf="showModelResultsPanel()">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Model Results
            </div>
            <div class="card-body">
                <hippocamp-model-results [projectID]="projectID" [treatmentBMPs]="projectTreatmentBMPs"
                    [delineations]="delineations" [projectNetworkSolveHistories]="projectNetworkSolveHistories">
                </hippocamp-model-results>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 mb-3" *ngIf="project?.CalculateOCTAM2Tier2Scores">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Grant Metrics
            </div>
            <div class="card-body">
                <hippocamp-grant-scores [project]="project"></hippocamp-grant-scores>
            </div>
        </div>
    </div>
</div>
<div class="row mt-4">
    <div class="col-auto ml-auto">
        <button class="btn btn-hippocamp ml-auto" (click)="continueToNextStep()">
            Continue
        </button>
    </div>
</div>
</ng-container>