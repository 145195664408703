<h2 class="d-inline-block">
    Project Instructions
</h2>
<custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>

<div class="row m-1 mt-3">
    <div class="col-auto ml-auto">
        <button class="btn btn-hippocamp ml-auto" (click)="continueToNextStep()">
            Continue
        </button>
    </div>
</div>