<div class="container-fluid">
    <app-alert-display></app-alert-display>
    <div class="row mb-2 justify-content-center">
        <div class="col-12 col-lg-11">
            <h2 class="d-inline-block">Planning Map</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 col-lg-11">
            <div class="row no-gutters">
                <div class="col-12 col-md-6 col-lg-4 order-last order-md-first d-flex flex-column"
                    [style.height]="mapHeight">
                    <div class="card">
                        <button class="m-0 border-0 p-0 text-left" type="button" data-toggle="collapse"
                            data-target="#mapOverlayCardBody" aria-expanded="true" aria-controls="mapOverlayCardBody">
                            <div class="card-header dropdown-toggle">
                                Map Overlay
                            </div>
                        </button>
                        <div id="mapOverlayCardBody" class="collapse show">
                            <div class="card-body d-flex flex-column">
                                <div class="row">
                                    <div class="col-12">
                                        Select an Overlay:
                                        <ng-select [(ngModel)]="selectedPrioritizationMetric"
                                            [items]="prioritizationMetrics" bindLabel="key" [clearable]="false"
                                            (change)="applyPrioritizationMetricOverlay()">
                                        </ng-select>
                                    </div>
                                    <div class="col-12" *ngIf="selectedPrioritizationMetric != PrioritizationMetric.NoMetric">
                                        <ng-container [ngSwitch]="selectedPrioritizationMetric.toString()">
                                            <p *ngSwitchCase="'Strategically Effective Area Score'">
                                                <field-definition [fieldDefinitionType]="'SEAScore'"></field-definition> Legend
                                            </p>
                                            <p class="text-center" *ngSwitchCase="'Transportation Nexus Score'">
                                                <field-definition [fieldDefinitionType]="'TPIScore'"></field-definition> Legend
                                            </p>
                                            <p class="text-center" *ngSwitchCase="'Receiving Water Score'">
                                                <field-definition [fieldDefinitionType]="'ReceivingWaterScore'"></field-definition> Legend
                                            </p>
                                            <p class="text-center" *ngSwitchCase="'Land Use Based Water Quality Need Score'">
                                                <field-definition [fieldDefinitionType]="'LandUseBasedWaterQualityScore'"></field-definition> Legend
                                            </p>
                                            <p class="text-center" *ngSwitchDefault></p>
                                        </ng-container>
                                        <div class="row justify-content-center no-gutters">
                                            <div class="col-6">
                                                <div
                                                    *ngFor="let legendColor of selectedPrioritizationMetric.legendColors | slice: 0 : 4; index as i">
                                                    <div class="metric-color-swatch d-inline-block align-middle"
                                                        [style.background]="legendColor">
                                                    </div>
                                                    <span class="align-middle">
                                                        {{selectedPrioritizationMetric.legendValues[i]}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div
                                                    *ngFor="let legendColor of selectedPrioritizationMetric.legendColors | slice: 4; index as i">
                                                    <div class="metric-color-swatch d-inline-block align-middle"
                                                        [style.background]="legendColor">
                                                    </div>
                                                    <span class="align-middle">
                                                        {{selectedPrioritizationMetric.legendValues[i+4]}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Hard code background color for now as best option to have scrollable overflow with a responsive growth div -->
                    <div class="card d-flex flex-grow-1" style="overflow-y:auto; background-color: #f9f9f9;">
                        <div class="card-header sticky-top">
                            Selected Project
                        </div>
                        <div *ngIf="selectedProject != null; else noProjectSelected" class="card-body">
                                <div class="d-flex align-items-end">
                                    <strong>Project</strong>
                                    <a routerLink="/projects/{{selectedProject.ProjectID}}"
                                        class="d-inline-block btn btn-hippocamp ml-auto">View Project Details</a>
                                </div>
                                <dl class="row mt-3">
                                    <dt class="text-sm-right col-sm-5 col-xs-12">Name</dt>
                                    <dd class="col-sm-7 col-xs-12">
                                        {{selectedProject.ProjectName}}
                                    </dd>

                                    <dt class="text-sm-right col-sm-5 col-xs-12">
                                        <field-definition fieldDefinitionType="Jurisdiction"></field-definition>
                                    </dt>
                                    <dd class="col-sm-7 col-xs-12">
                                        {{selectedProject.StormwaterJurisdiction.Organization.OrganizationName}}
                                    </dd>
                                </dl>
                                <ng-container *ngIf="selectedTreatmentBMP != null">
                                    <strong>Selected BMP</strong>
                                    <dl class="row mt-2">
                                        <dt class="text-sm-right col-sm-5 col-xs-12">Name</dt>
                                        <dd class="col-sm-7 col-xs-12">
                                            {{selectedTreatmentBMP.TreatmentBMPName}}
                                        </dd>
    
                                        <dt class="text-sm-right col-sm-5 col-xs-12">
                                            <field-definition fieldDefinitionType="TreatmentBMPType"
                                                [labelOverride]="'Type'">
                                            </field-definition>
                                        </dt>
                                        <dd class="col-sm-7 col-xs-12">
                                            {{selectedTreatmentBMP.TreatmentBMPTypeName}}
                                        </dd>
    
                                        <dt class="text-sm-right col-sm-5 col-xs-12">Status</dt>
                                        <dd class="col-sm-7 col-xs-12">
                                            {{selectedProject.ProjectStatus.ProjectStatusDisplayName}}
                                        </dd>
                                        <ng-container *ngIf="selectedDelineation != null">
                                            <dt class="text-sm-right col-sm-5 col-xs-12">
                                                <field-definition fieldDefinitionType="Area" [labelOverride]="'Delineation Area'"></field-definition>
                                            </dt>
                                            <dd class="col-sm-7 col-xs-12">
                                                {{selectedDelineation.DelineationArea}} ac
                                            </dd>
    
                                            <dt class="text-sm-right col-sm-5 col-xs-12">
                                                <field-definition fieldDefinitionType="DelineationType"></field-definition>
                                            </dt>
                                            <dd class="col-sm-7 col-xs-12">
                                                {{selectedDelineation.DelineationTypeName}}
                                            </dd>
                                        </ng-container>
                                    </dl>
                                </ng-container>
                                <ng-container *ngIf="relatedTreatmentBMPs.length != 0">
                                    <strong>Other BMPs</strong>
                                    <dl class="row mt-2">
                                        <ng-container *ngFor="let bmp of getRelatedBMPsToShow()">
                                            <dt class="col-12 col-sm-5 text-sm-right mb-1">
                                                <button class="btn btn-primary btn-sm"
                                                    (click)="selectTreatmentBMPImpl(bmp.TreatmentBMPID)">Select</button>
                                            </dt>
                                            <dd class="col-12 col-sm-7 mb-1">
                                                {{bmp.TreatmentBMPName}}
                                            </dd>
                                        </ng-container>
                                        <div class="col font-italic" *ngIf="getRelatedBMPsToShow().length == 0">
                                            There are no other BMPs associated with this project.
                                        </div>
                                    </dl>
                                </ng-container>
                        </div>
                        <ng-template #noProjectSelected>
                            <div class="card-body">
                                Select a Treatment BMP on the map to see information about the
                                Treatment BMP, its Project and related Treatment BMPs, and Delineation if present. 
                                Alternatively, select a row in the grid below to bring up the selected project and choose
                                a BMP from the populated panel.
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-8 mb-1 mb-md-0">
                    <div id={{mapID}} class="location-card" [style.min-height]="mapHeight" [style.height]="'100%'">
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-auto ml-auto">
                    <hippocamp-clear-grid-filters-button [grid]="projectsGrid" class="mr-2">
                    </hippocamp-clear-grid-filters-button>
                </div>
            </div>
            <ag-grid-angular #projectsGrid class="ag-theme-balham" style="width: 100%; height: 700px;"
                [columnDefs]="columnDefs" [rowData]="projects" [defaultColDef]="defaultColDef" [pagination]="true"
                [rowSelection]="'single'" [paginationPageSize]="paginationPageSize" suppressMenuHide="true" (selectionChanged)="onSelectionChanged($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>