<header-nav></header-nav>
<div class="mh-auto min-vh-100 page-content">
    <router-outlet></router-outlet>
</div>
<footer class="footer mt-2 pt-4 pb-3">
    <div class="container">
        <p class="text-center">
            This program is free software; you can redistribute it and/or modify it under the terms of the <a href="https://www.gnu.org/licenses/agpl-3.0.html">GNU Affero General Public License</a> as published by the <a href="https://www.fsf.org/">Free Software Foundation</a>.
            Disclaimers, copyright, and source code are available <a href="{{legalUrl()}}">here</a>.
        </p>
        <div class="col text-center">
            Designed, Built, and Maintained by <a href="https://esassoc.com/" target="_blank">Environmental Science Associates</a>.
        </div>
    </div>
</footer>
