<nav class="navbar navbar-expand-xl justify-content-start navbar-hippocamp navbar-dark navbar-responsive-header">

    <a routerLink="/" routerLinkActive="active" class="navbar-brand nav-link">
        OCST Planning Module
    </a>
    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar2"
        (click)="toggleCurrentPageHeader()">
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>

<nav class="navbar navbar-expand-xl justify-content-start navbar-hippocamp-secondary mb-2">
    <a href="{{ocStormwaterToolsMainUrl()}}" class="navbar-brand navbar-logo pt-0" target="_blank">
        <img src="assets/main/logos/hippocamp_logo.png" class="navbarLogo" alt="OCST Planning Module">
    </a>
    <a routerLink="/" routerLinkActive="active" class="navbar-brand nav-link">
        OCST Planning Module
    </a>

    <div class="flex-row navbar-collapse collapse float-right" id="navbar2">
        <ul class="navbar-nav mt-lg-0 mr-sm-2 ml-3">
            <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link">Sign In</a>
            </li>
            <li class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="isAuthenticated()">
                <a href="#" class="nav-link dropdown-toggle pr-3" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Welcome {{getUserName()}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a href="javascript:void(0);" (click)="logout()" class="dropdown-item">Sign Out</a>
                </div>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/about" class="nav-link">
                    About
                </a>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active">
                <a href="#" class="nav-link dropdown-toggle pr-3" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Help
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a href="{{requestSupportUrl()}}" class="dropdown-item">
                        Request Support
                    </a>
                    <a routerLink="/training" class="dropdown-item">
                        Training
                    </a>
                </div>
            </li>
        </ul>
    </div>

</nav>


<nav class="navbar navbar-expand-xl navbar-hippocamp">
    <div class="collapse navbar-collapse" id="navbar2">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <ng-container *ngIf="isAuthenticated() && isNotUnassigned()">
                <li class="nav-item" routerLinkActive="active">
                    <a href="/projects" class="nav-link" role="button">
                        Projects
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a href="/planning-map" class="nav-link" role="button">
                        Planning Map
                    </a>
                </li>
            </ng-container>
            <li class="nav-item dropdown" routerLinkActive="active" *ngIf="isAdministrator()">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Manage
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a href="{{usersListUrl()}}" class="dropdown-item">
                        Users
                    </a>
                    <a href="{{organizationsIndexUrl()}}" class="dropdown-item">
                        Organizations
                    </a>
                    <a routerLink="/labels-and-definitions" class="dropdown-item">
                        Labels and Definitions
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active" *ngIf="isOCTAGrantReviewer()">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Grant Programs
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a href="grant-programs/octa-m2-tier-2" class="dropdown-item">
                        OCTA M2 Tier 2 Grant Program
                    </a>
                </div>
            </li>
        </ul>

    </div>
</nav>