<div class="container-fluid">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Projects</li>
        </ol>
    </nav>
    <app-alert-display></app-alert-display>
    <div class="row mb-2">
        <div class="col-12">
            <h2>Projects</h2>
        </div>
        <div class="col-12">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-auto ml-auto">
            <hippocamp-clear-grid-filters-button [grid]="projectsGrid" class="mr-2"></hippocamp-clear-grid-filters-button>
            <!-- <a class="btn btn-secondary mr-2" style="cursor: pointer;" (click)="exportToCsv()" placement="top" ngbTooltip="Download Projects as CSV"><i
                class="fas fa-download"></i></a> -->
            <a class="btn btn-hippocamp mr-2" href="/projects/new">
                <i class="fa fa-plus"></i> &nbsp;
                Add New Project
            </a>
            <div class="btn-group">
                <button class="btn btn-outline-primary btn-md dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-download mr-2"></i>Download
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="exportToCsv()">Project List</a>
                    <a class="dropdown-item" (click)="downloadProjectModelResults()">Project List with Model Results</a>
                    <a class="dropdown-item" (click)="downloadTreatmentBMPModelResults()">BMP List with Model Results</a>
                    <a class="dropdown-item" [href]="downloadTreatmentBMPDelineationShapefile()">BMP Delineation Shapefile</a>
                    <a class="dropdown-item" [href]="downloadTreatmentBMPLocationPointShapefile()">BMP Location Points Shapefile</a>
                </div>
            </div>
        </div>
    </div>
    <ag-grid-angular #projectsGrid class="ag-theme-balham" style="width: 100%; height: 700px;"
        [columnDefs]="projectColumnDefs" [defaultColDef]="defaultColDef"
        [pagination]="true" [paginationPageSize]="100" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)" 
        [suppressMenuHide]="true">
    </ag-grid-angular>
</div>

<ng-template #deleteProjectModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="deleteProjectModalTitle">Warning</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You are about to delete {{projectNameToDelete}}.
        </p>
        <p>Are you sure you wish to proceed?</p>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" (click)="deleteProject()">
                <span *ngIf="isLoadingDelete" class="fa fa-spinner loading-spinner"></span>
                Delete
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>