<ng-container *ngIf="model">
    <h2 class="d-inline-block">
        Project Attachments
    </h2>
    <div class="row mt-2 mb-2 mr-2">
        <div class="col-12">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col">
            <div class="card mr-4">
                <div class="card-header">
                    Add Attachment
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmit(addAttachmentForm)" #addAttachmentForm="ngForm">
                        <div class="row mt-4">
                            <div class="col-sm-3 control-label text-sm-right">
                                <label class="required">File</label>
                            </div>
                            <div class="input-group col-sm-7">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input"
                                        id="fileResource"
                                        #fileUpload (change)="fileEvent()"
                                        [ngClass]='{"field-invalid": isFieldInvalid("FileResource")}' />    
                                    <label class="custom-file-label"
                                        for="fileResource">
                                        {{getFileName()}}
                                    </label>
                                </div>
                                <div class="input-group-append">
                                    <button class="btn btn-hippocamp input-group-append" type="button"
                                        (click)="openFileUpload()">
                                        <hippocamp-icon icon="file" class="file" container="body">
                                        </hippocamp-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-7">
                                <em class="text-small mt-2">Allowed extensions: pdf, zip, doc, docx, xls, xlsx, jpg, png. Max file size: 30 MB.</em>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-7">
                                <em class="text-danger mt-2" *ngIf="displayFileErrors">File must be one of: pdf, zip, doc, docx, xls, xlsx, jpg, png</em>
                            </div>
                        </div>
                    
                        <div class="row mt-4">
                            <div class="col-sm-3 control-label text-sm-right">
                                <label class="required">Display Name</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text" class="form-control" name="display-name" [(ngModel)]="model.DisplayName" required
                                [ngClass]='{"field-invalid": isFieldInvalid("DisplayName")}'>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-3 control-label text-sm-right">
                                <label>Description</label>
                            </div>
                            <div class="col-sm-7">
                                <textarea class="form-control" name="description" style="height: 100px" placeholder=""
                                    [(ngModel)]="model.DocumentDescription" [ngClass]='{"field-invalid": isFieldInvalid("DocumentDescription")}'>
                                </textarea>
                            </div>
                        </div>
    
                        <div class="row mt-4">
                            <div class="col-sm-2">
                                <i class="fas fa-bolt"></i> Required field
                            </div>
                            <div class="col-sm-8 text-right">
                                <button type="submit" class="btn btn-hippocamp" [disabled]="isLoadingSubmit">
                                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                                    Save
                                </button>
                                <button type="reset" class="btn btn-secondary ml-1" [disabled]="isLoadingSubmit"
                                    (click)="resetAttachmentForm(addAttachmentForm)">
                                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                                    Clear
                                </button>
                            </div>
                        </div>
                
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row mt-3">
        <div class="col">
            <h5 class="d-inline-block">
                Attachments Added
            </h5>
    
        </div>
    </div>
    
    <ng-container *ngIf="attachments && attachments.length > 0">
        <hippocamp-attachments-display [attachments]="attachments" [readOnly]="false" (onDeleteTriggered)="launchDeleteModal(deleteAttachmentModal, $event)" (onEditTriggered)="launchEditModal(editAttachmentModal, $event)"></hippocamp-attachments-display>
    </ng-container>
    
    <div class="row m-1 mt-3">
        <div class="col-auto ml-auto">
            <button class="btn btn-hippocamp ml-auto" (click)="continueToNextStep()">
                Continue
            </button>
        </div>
    </div>
</ng-container>

<ng-template #deleteAttachmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="deleteAttachmentModalTitle">Delete Attachment</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Are you sure you wish to delete this attachment?
            <br/>
            This action cannot be undone
        </p>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-md" (click)="deleteAttachment()">
                <span *ngIf="isLoadingDelete" class="fa fa-spinner loading-spinner"></span>
                Delete
            </button>
            <button type="button" class="btn btn-secondary btn-md" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #editAttachmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="selectAccountModalTitle">Edit Attachment</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="onEditSubmit(editAttachmentForm)" #editAttachmentForm="ngForm">
            <div class="row mt-4">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Display Name</label>
                </div>
                <div class="col-sm-7">
                    <input type="text" class="form-control" name="display-name" [(ngModel)]="editModel.DisplayName" required
                    [ngClass]='{"field-invalid": isFieldInvalid("DisplayName")}'>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Description</label>
                </div>
                <div class="col-sm-7">
                    <textarea class="form-control" name="description" style="height: 100px" placeholder=""
                        [(ngModel)]="editModel.DocumentDescription" [ngClass]='{"field-invalid": isFieldInvalid("DocumentDescription")}'> 
                    </textarea>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-sm-2">
                    <i class="fas fa-bolt"></i> Required field
                </div>
                <div class="col-sm-8 text-right">
                    <button type="submit" class="btn btn-hippocamp btn-md ml-2" [disabled]="isLoadingSubmit">
                        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                        Save
                    </button>
                    <button type="button" class="btn btn-secondary btn-md ml-2" (click)="modal.close('Cancel click')">Cancel</button>
                </div>
            </div>
    
        </form>
    </div>
</ng-template>