//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FieldDefinitionType]

export enum FieldDefinitionTypeEnum {
  IsPrimaryContactOrganization = 1,
  Organization = 2,
  Password = 3,
  MeasurementUnit = 4,
  PhotoCaption = 5,
  PhotoCredit = 6,
  PhotoTiming = 7,
  PrimaryContact = 8,
  OrganizationType = 9,
  Username = 10,
  ExternalLinks = 11,
  RoleName = 12,
  ChartLastUpdatedDate = 13,
  TreatmentBMPType = 14,
  ConveyanceFunctionsAsIntended = 16,
  AssessmentScoreWeight = 17,
  ObservationScore = 18,
  AlternativeScore = 19,
  AssessmentForInternalUseOnly = 20,
  TreatmentBMPDesignDepth = 21,
  ReceivesSystemCommunications = 22,
  Jurisdiction = 23,
  Delineation = 24,
  TreatmentBMP = 25,
  TreatmentBMPAssessmentObservationType = 26,
  ObservationCollectionMethod = 27,
  ObservationThresholdType = 28,
  ObservationTargetType = 29,
  MeasurementUnitLabel = 30,
  PropertiesToObserve = 31,
  MinimumNumberOfObservations = 32,
  MaximumNumberOfObservations = 33,
  MinimumValueOfEachObservation = 34,
  MaximumValueOfEachObservation = 35,
  DefaultThresholdValue = 36,
  DefaultBenchmarkValue = 37,
  AssessmentFailsIfObservationFails = 38,
  CustomAttributeType = 39,
  CustomAttributeDataType = 40,
  MaintenanceRecordType = 41,
  MaintenanceRecord = 42,
  AttributeTypePurpose = 43,
  FundingSource = 44,
  IsPostMaintenanceAssessment = 45,
  FundingEvent = 46,
  FieldVisit = 47,
  FieldVisitStatus = 48,
  WaterQualityManagementPlan = 49,
  Parcel = 50,
  RequiredLifespanOfInstallation = 51,
  RequiredFieldVisitsPerYear = 52,
  RequiredPostStormFieldVisitsPerYear = 53,
  WaterQualityManagementPlanDocumentType = 54,
  HasAllRequiredDocuments = 55,
  DateOfLastInventoryChange = 56,
  TrashCaptureStatus = 57,
  OnlandVisualTrashAssessment = 58,
  OnlandVisualTrashAssessmentNotes = 59,
  DelineationType = 60,
  BaselineScore = 61,
  SizingBasis = 62,
  ProgressScore = 63,
  AssessmentScore = 64,
  ViaFullCapture = 65,
  ViaPartialCapture = 66,
  ViaOVTAScore = 67,
  TotalAchieved = 68,
  TargetLoadReduction = 69,
  TrashGenerationRate = 70,
  LandUse = 71,
  Area = 72,
  ImperviousArea = 73,
  GrossArea = 74,
  LandUseStatistics = 75,
  RegionalSubbasin = 76,
  AverageDivertedFlowrate = 77,
  AverageTreatmentFlowrate = 78,
  DesignDryWeatherTreatmentCapacity = 79,
  DesignLowFlowDiversionCapacity = 80,
  DesignMediaFiltrationRate = 81,
  DesignResidenceTimeForPermanentPool = 82,
  DiversionRate = 83,
  DrawdownTimeForWQDetentionVolume = 84,
  EffectiveFootprint = 85,
  EffectiveRetentionDepth = 86,
  InfiltrationDischargeRate = 87,
  InfiltrationSurfaceArea = 88,
  MediaBedFootprint = 89,
  MonthsOfOperationID = 90,
  PermanentPoolOrWetlandVolume = 91,
  RoutingConfiguration = 92,
  StorageVolumeBelowLowestOutletElevation = 93,
  SummerHarvestedWaterDemand = 94,
  TimeOfConcentrationID = 95,
  DrawdownTimeForDetentionVolume = 96,
  TotalEffectiveBMPVolume = 97,
  TotalEffectiveDrywellBMPVolume = 98,
  TreatmentRate = 99,
  UnderlyingHydrologicSoilGroupID = 100,
  UnderlyingInfiltrationRate = 101,
  UpstreamBMP = 102,
  WaterQualityDetentionVolume = 103,
  WettedFootprint = 104,
  WinterHarvestedWaterDemand = 105,
  PercentOfSiteTreated = 106,
  PercentCaptured = 107,
  PercentRetained = 108,
  AreaWithinWQMP = 109,
  Watershed = 110,
  DesignStormwaterDepth = 111,
  FullyParameterized = 112,
  HydromodificationApplies = 113,
  DelineationStatus = 114,
  DryWeatherFlowOverrideID = 115,
  ModeledPerformance = 116,
  OCTAM2Tier2GrantProgram = 117,
  SEAScore = 118,
  TPIScore = 119,
  WQLRI = 120,
  PollutantContributiontoSEA = 121,
  SiteRunoff = 122,
  TreatedAndDischarged = 123,
  RetainedOrRecycled = 124,
  UntreatedBypassOrOverflow = 125,
  TotalSuspendedSolids = 126,
  TotalNitrogen = 127,
  TotalPhosphorous = 128,
  FecalColiform = 129,
  TotalCopper = 130,
  TotalLead = 131,
  TotalZinc = 132,
  OCTAWatershed = 133,
  EffectiveAreaAcres = 134,
  DesignStormDepth85thPercentile = 135,
  DesignVolume85thPercentile = 136,
  LandUseBasedWaterQualityScore = 137,
  ReceivingWaterScore = 138,
  NumberOfIndividualBMPs = 139,
  LandUseType = 140,
  LandUseDescription = 141
}
